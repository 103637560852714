/** @flow */
import React from 'react'
import PropTypes from 'prop-types'

import Theme from '../../../context/theme'
import ContainerRoot from './styled'

const containerPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fluid: PropTypes.bool,
  marginBottom: PropTypes.bool,
}

/**
 * Anchor
 *
 * @param {*} props
 * @returns
 */
const Container = ({ children, fluid, marginBottom, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  return (
    <ContainerRoot
      {...otherProps}
      $fluid={fluid}
      $marginBottom={marginBottom}
      $theme={theme}
    >
      {children}
    </ContainerRoot>
  )
}

/** Component Property Types */
// eslint-disable-next-line react/no-typos
Container.propTypes = containerPropTypes
Container.defaultProps = {
  fluid: false,
  marginBottom: false,
}

export default Container
