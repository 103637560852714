import { styled } from 'styletron-react'

const borderStyles = ({ $rounded, $hasError, $theme }) => {
  const color = $hasError ? $theme.palette.error : '#d9d9d9'

  return {
    borderTop: `1px solid ${color}`,
    borderBottom: `1px solid ${color}`,
    borderRight: `1px solid ${color}`,
    borderLeft: `1px solid ${color}`,
    borderRadius: $rounded ? `${$theme.shape.small}px` : 0,
  }
}

const FormFieldRoot = styled(
  'input',
  ({ $fullWidth, $rounded, $transparent, $theme, $hasError }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    ...$theme.typography.base,
    ...($fullWidth ? { width: '100%' } : {}),
    minHeight: '44px', // 44
    // Gutters / Padding
    paddingTop: `${$theme.spacing(1)}px`,
    paddingBottom: `${$theme.spacing(1)}px`,
    ...$theme.mixins.gutters(),
    marginTop: '0',
    marginRight: '0',
    marginBottom: '0',
    marginLeft: '0',
    // Borders
    ...borderStyles({ $rounded, $hasError, $theme }),
    // Error
    ...($hasError
      ? {
          backgroundColor: $theme.palette.error50,
          color: $theme.palette.error,
        }
      : {}),
    // Transparency
    ...($transparent
      ? {
          border: 0,
          backgroundColor: 'transparent',
          color: 'inherit',
        }
      : {}),
  }),
)

export default FormFieldRoot
