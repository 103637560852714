import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => (
  <div>
    <h1>Not Found</h1>
    <p>Unfortunately, we could not find that page!</p>
    <Link to="/">Take me home</Link>
  </div>
);

export default PageNotFound;
