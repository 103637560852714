/** @flow */
import React from 'react';

/** Components */
import SpacerRoot from './style';

/**
 * Spacer Component
 *
 * @param {*} props
 * @returns
 */
const Spacer = ({ ...otherProps }) => <SpacerRoot {...otherProps} />;

export default Spacer;
