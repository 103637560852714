import { styled, withStyle } from 'styletron-react'

import ContainerRoot from '../container/styled'

// Theme Styles
const themeStyles = ({ $color, swap, $theme }) => {
  const color = $color === 'default' ? 'gray100' : $color
  const styles = $theme.styler({ color, swap, outlined: false })
  return styles
}

/** Appbar Root Element Component */
const AppbarRoot = styled('header', ({ $color, $theme }) => ({
  zIndex: $theme.zIndex.appbar,
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  marginBottom: `${$theme.spacing(2)}px`,
  ...themeStyles({ $color, $theme }),
}))

/** Appbar Toolbar Element Component */
export const AppbarToolbar = withStyle(ContainerRoot, ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...$theme.mixins.toolbar,
}))

export default AppbarRoot
