import { styled, withStyle } from 'styletron-react';
import { Link } from 'react-router-dom';

import lightenDarken from '../../../context/theme/functions/ligthenDarken';

// Theme Styles
const themeStyles = ({ $color, $variant, $isHovered, disabled, $theme }) => {
  if (!$color || $color === 'initial') return {};

  const outlined = $variant === 'outlined';
  const swap = outlined || $variant === 'text';

  let color = $color === 'default' ? 'text' : $color;
  if ($color === 'default' && !swap) color = 'gray100';
  if (disabled) color = 'gray50';

  const styles = $theme.styler({ color, swap, outlined: true });

  if ($isHovered && !disabled) {
    let amount = swap ? 200 : -30;
    if (swap && $color === 'default') amount = 100;

    const hoverColor = lightenDarken(
      styles.backgroundColor !== 'transparent'
        ? styles.backgroundColor
        : styles.color,
      amount,
    );

    if (hoverColor === styles.color) {
      styles.color = lightenDarken(styles.color, -30);
    }

    styles.backgroundColor =
      hoverColor === styles.backgroundColor
        ? lightenDarken(hoverColor, 30)
        : hoverColor;
  } else if (disabled) {
    styles.color = lightenDarken(styles.color, swap ? -30 : 100);
  }

  return {
    ...styles,
    borderColor: outlined ? styles.color : styles.backgroundColor,
  };
};

// Padding styles
const buttonPadding = (horizontal, vertical) => ({
  paddingTop: `${vertical}px`,
  paddingBottom: `${vertical}px`,
  paddingLeft: `${horizontal}px`,
  paddingRight: `${horizontal}px`,
});

export const ButtonLabelRoot = styled('span', ({ $theme }) => ({
  width: '100%',
  display: 'inherit',
  alignItems: 'inherit',
  justifyContent: 'inherit',
  ...$theme.typography.button,
}));

/** Base Button Element Component */
const ButtonRoot = styled('button', ({ $theme, ...otherProps }) => {
  const { typography, shadows } = $theme;
  const {
    $margin,
    $raised,
    $rounded,
    $align,
    $fullWidth,
    $size,
    $as,
  } = otherProps;
  const disabled = otherProps.disabled || false;

  const size = {
    tiny: 24,
    small: 34,
    medium: 44,
    large: 54,
  };

  const paddings = {
    tiny: [0.5, 0.25],
    small: [1, 0.5],
    medium: [2, 1],
    large: [2.5, 1.5],
  };

  const fontSize = {
    tiny: '86%',
    small: '93%',
    medium: '100%',
    large: '107%',
  };

  return {
    // position: 'relative',
    display: 'inline-flex',
    justifyContent: $align,
    alignItems: 'center',
    ...typography.button,
    ...($as === Link ? { lineHeight: 1.63 } : {}),
    textDecoration: 'none',
    cursor: disabled ? 'default' : 'pointer',
    fontSize: fontSize[$size],
    margin: `${$margin}px`,
    boxShadow: shadows[!disabled && $raised ? 2 : 0],
    ...buttonPadding(
      $theme.spacing(paddings[$size][0]),
      $theme.spacing(paddings[$size][1]),
    ),
    ...themeStyles({ ...otherProps, $theme }),
    borderRadius: $rounded ? `${$theme.shape.small}px` : 0,
    ...($fullWidth ? { width: '100%', maxWidth: '100%' } : {}),
    minWidth: '44px',
    minHeight: `${$as !== Link ? size[$size] : 0}px`,
    ':focus': { outline: 'none' },
    ':before': { content: '""' },
  };
});

export const IconButtonRoot = withStyle(
  ButtonRoot,
  ({ $theme, ...otherProps }) => {
    const { $size } = otherProps;

    const size = {
      tiny: 24,
      small: 34,
      medium: 44,
      large: 54,
    };

    const paddings = {
      tiny: [0.25, 0.25],
      small: [0.5, 0.5],
      medium: [1, 1],
      large: [1.5, 1.5],
    };

    return {
      flex: '0 0 auto',
      justifyContent: 'center',
      width: `${size[$size]}px`,
      minWidth: 'auto',
      height: `${size[$size]}px`,
      ...buttonPadding(
        $theme.spacing(paddings[$size][0]),
        $theme.spacing(paddings[$size][1]),
      ),
    };
  },
);

export const FabRoot = withStyle(IconButtonRoot, () => ({
  borderRadius: '50%',
}));

export default ButtonRoot;
