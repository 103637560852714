import React from 'react'
import PropTypes from 'prop-types'

import BaseInput from './styled'
import Theme from '../../../context/theme'

const checkboxPropTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}

const CheckBox = React.forwardRef(
  ({ color, disabled, onChange, value, ...otherProps }, ref) => {
    const { theme } = React.useContext(Theme)

    const handleChange = event => {
      if (onChange) onChange(event)
    }

    const checkboxId = otherProps.id || `checkbox_${new Date().valueOf()}`
    return (
      <BaseInput
        {...otherProps}
        ref={ref}
        id={checkboxId}
        type={otherProps.as === 'radio' ? 'radio' : 'checkbox'}
        $color={color}
        $theme={theme}
        disabled={disabled}
        aria-labelledby={`${checkboxId}_label`}
        onChange={handleChange}
        value={value}
      />
    )
  },
)

export const Radio = props => <CheckBox as="radio" {...props} />

/** Component Property Types */
CheckBox.propTypes = checkboxPropTypes
CheckBox.defaultProps = {
  color: 'default',
  disabled: false,
  onChange: () => {},
  value: '',
}

export default CheckBox
