// const BASE_FONTS = '"Source Sans Pro", sans-serif'
const HEADING_FONTS = 'Raleway, Helvetica, Arial, sans-serif'

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  bolder: 800,
  extraBold: 900,
}

/** BODY STYLES */
const bodyStyles = {
  // fontFamily: BASE_FONTS,
  fontFamily: 'inherit',
  fontSize: '16px',
  fontWeight: fontWeights.regular,
  lineHeight: '20px',
}

/** HEADING TAG STYLES */
const headingStyles = [...new Array(6)]
  .map((c, i) => i + 1)
  .map(h => {
    const fontSize = Math.floor((24 / (100 + (h - 1) * 10)) * 100)
    const lineHeight = Math.floor((fontSize / 100) * 117)

    return {
      [`h${h}`]: {
        fontFamily: HEADING_FONTS,
        fontStyle: 'normal',
        fontWeight: fontWeights.regular, // BOLD
        fontSize: `${fontSize}px`,
        lineHeight: `${lineHeight}px`,
      },
    }
  })
  .reduce((r, c) => Object.assign(r, c), {})

export default {
  // Font Weights
  ...Object.keys(fontWeights).reduce(
    (prev, current) => ({
      ...prev,
      [`fontWeight${current[0].toUpperCase()}${current.substring(
        1,
      )}`]: fontWeights[current],
    }),
    {},
  ),
  // Base styles, Used to set base font styles.. i.e. BODY
  base: { ...bodyStyles },
  small: {
    ...bodyStyles,
    fontSize: 'smaller',
    lineHeight: 1,
  },
  // HEADINGS
  ...headingStyles,
  // BUTTONS
  button: {
    ...bodyStyles,
    // fontSize: '0.875rem',
    lineHeight: 1.75,
    fontStyle: 'normal',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}
