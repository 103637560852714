import systemColors from '../colors/system';
import brandColors from '../colors/brand';
import accentColors from '../colors/accent';
import commonColors from '../colors/common';

const colors = {
  ...commonColors,
  ...systemColors,
  ...brandColors,
  ...accentColors,
};

function padZero(str, len) {
  let value = len;
  value = value || 2;
  const zeros = new Array(value).join('0');
  return (zeros + str).slice(-value);
}

function invertColor(hex, bw = true) {
  let value = hex;
  if (!/^(#{1})?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/.test(value)) return hex;
  if (value.indexOf('#') === 0) value = value.slice(1);
  if (value.length === 3) value = value.split('').map(s => `${s}${s}`).join(''); // value[0] + value[0] + value[1] + value[1] + value[2] + value[2];
  if (value.length !== 6) throw new Error('Invalid HEX color.');

  let r = parseInt(value.slice(0, 2), 16);
  let g = parseInt(value.slice(2, 4), 16);
  let b = parseInt(value.slice(4, 6), 16);

  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? colors.text // '#000000'
      : colors.background; // #FFFFFF
  }

  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);

  // pad each with zeros and return
  return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
}

const colorStyles = ({ ref, swap }) => {
  // eslint-disable-next-line no-prototype-builtins
  const color = swap ? colors[ref] : invertColor(colors[ref]);
  const backgroundColor = swap ? 'transparent' : colors[ref];

  return {
    color,
    backgroundColor,
  };
};

const borders = color => {
  return {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: color,
  };
};

export default ({ color, swap, outlined }) => {
  const styles = { ...colorStyles({ ref: color, swap, outlined }) };
  return {
    ...styles,
    ...(outlined ? borders(styles.color) : {}),
  };
};
