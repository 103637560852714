import React from 'react'
import PropTypes from 'prop-types'

import BaseDivider from './styled'
import Theme from '../../../context/theme'

const dividerPropTypes = {
  color: PropTypes.string,
  opacity: PropTypes.number,
  size: PropTypes.number,
  borderStyle: PropTypes.string,
}

const Divider = ({ color, size, opacity, borderStyle, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  return (
    <BaseDivider
      {...otherProps}
      $color={color}
      $opacity={opacity}
      $size={size}
      $borderStyle={borderStyle}
      $theme={theme}
    />
  )
}

/** Component Property Types */
Divider.propTypes = dividerPropTypes
Divider.defaultProps = {
  color: 'default',
  opacity: 0.6,
  size: 1,
  borderStyle: 'solid',
}

export default Divider
