/*
  DO NOT MODIFY THIS FILE
*/
import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron, styled } from '../styles'

export const COLORS = {
  default: 'default',
  white: 'white',
  corporate: 'corporate',
  ownership: 'ownership',
  holidays: 'holidays',
  touring: 'touring',
  disabled: 'disabled',
  pl2kBlack: 'pl2kBlack'
}

export const SIZES = {
  micro: 'micro',
  mini: 'mini',
  small: 'small',
  regular: 'regular',
  title1: 'title1',
  title2: 'title2',
  title3: 'title3',
}

export const LEADING = {
  default: 'default',
  tall: 'tall',
}

export const WEIGHTS = {
  default: 'default',
  lighter: 'lighter',
  lightest: 'lightest',
  bolder: 'bolder',
  boldest: 'boldest',
}

const styles = {
  fontFamily: {
    title1:
      'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
    title2:
      'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
    title3:
      'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
    large:
      'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
    regular:
      'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
    small:
      'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
    mini:
      'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
    micro:
      'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
  },
  size: {
    title1: 38,
    title1_mediumAndAbove: 46,
    title2: 32,
    title3: 24,
    large: 18,
    regular: 16,
    small: 14,
    mini: 12,
    micro: 10,
  },
  leading: {
    default: {
      title1: 44,
      title1_mediumAndAbove: 52,
      title2: 36,
      title3: 30,
      large: 26,
      regular: 22,
      small: 18,
      mini: 16,
      micro: 12,
    },
    tall: {
      title1: 50,
      title1_mediumAndAbove: 60,
      title2: 44,
      title3: 40,
      large: 30,
      regular: 28,
      small: 24,
      mini: 18,
      micro: 14,
    },
  },
  tracking: {
    default: {
      title1: 0,
      title2: 0,
      title3: 0,
      large: 0,
      regular: 0,
      small: 0,
      mini: 0,
      micro: 0,
    },
    wide: {
      title1: 1,
      title2: 1,
      title3: 1,
      large: 1,
      regular: 1,
      small: 1,
      mini: 1,
      micro: 1,
    },
  },
  weight: {
    lightest: {
      title1: '400',
      title2: '400',
      title3: '400',
      large: '400',
      regular: '400',
      small: '400',
      mini: '400',
      micro: '400',
    },
    lighter: {
      title1: '600',
      title2: '600',
      title3: '400',
      large: '400',
      regular: '400',
      small: '400',
      mini: '400',
      micro: '400',
    },
    default: {
      title1: '800',
      title2: '800',
      title3: '400',
      large: '400',
      regular: '400',
      small: '400',
      mini: '400',
      micro: '400',
    },
    bolder: {
      title1: '800',
      title2: '800',
      title3: '600',
      large: '600',
      regular: '600',
      small: '600',
      mini: '600',
      micro: '600',
    },
    boldest: {
      title1: '800',
      title2: '800',
      title3: '800',
      large: '800',
      regular: '800',
      small: '800',
      mini: '800',
      micro: '800',
    },
  },
  color: {
    white: '#ffffff',
    black: '#000000',
    corporate: '#0c5595',
    ownership: '#962067',
    default: '#666666',
    pl2kBlue: '#26b1bd',
    pl2kBlack: '#06181E'
  },
}

const propTypes = {
  allCaps: PropTypes.bool,
  ariaCurrent: PropTypes.oneOf([
    'page',
    'step',
    'location',
    'date',
    'time',
    'true',
  ]),
  ariaHidden: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(COLORS)),
  htmlTitle: PropTypes.string,
  id: PropTypes.string,
  inline: PropTypes.bool,
  leading: PropTypes.oneOf(Object.keys(LEADING)),
  lineClamp: PropTypes.oneOf([1, 2, 3, 4]),
  noLineClampForPrint: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  textAlign: PropTypes.oneOf([
    'left',
    'right',
    'center',
    'justify',
    'initial',
    'inherit',
  ]),
  textRef: PropTypes.func,
  weight: PropTypes.oneOf(Object.keys(WEIGHTS)),
}

const defaultProps = {
  allCaps: false,
  ariaCurrent: undefined,
  ariaHidden: undefined,
  className: null,
  color: COLORS.default,
  htmlTitle: null,
  id: undefined,
  inline: false,
  leading: LEADING.default,
  lineClamp: null,
  noLineClampForPrint: false,
  size: SIZES.regular,
  textAlign: null,
  textRef: () => {},
  weight: WEIGHTS.default,
}

const Text = ({
  allCaps,
  ariaCurrent,
  ariaHidden,
  children,
  className,
  color,
  htmlTitle,
  id,
  inline,
  leading,
  lineClamp,
  noLineClampForPrint,
  size,
  textAlign,
  textRef,
  weight,
}) => {
  const [css] = useStyletron()
  const element = inline ? 'span' : 'div'
  return React.createElement(
    element,
    {
      'aria-disabled': color === COLORS.disabled || undefined,
      'aria-current': ariaCurrent ? ariaCurrent : undefined,
      'aria-hidden': !!ariaHidden || undefined,
      id: id ? id : undefined,
      ref: textRef,
      title: htmlTitle,
      className: `${css({
        ...(allCaps && {
          textTransform: 'uppercase',
        }),
        ...(lineClamp && {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }),
        ...(noLineClampForPrint && {
          display: 'block',
          maxHeight: 'none',
          overflow: 'visible',
          WebkitLineClamp: 'none',
          whiteSpace: 'normal',
        }),
        ...(size && { fontSize: `${styles.size[size]}px` }),
        ...(weight && size && { fontWeight: styles.weight[weight][size] }),
        ...(color && { color: styles.color[color] }),
      })} ${className}`,
      // Styletron-only props...
      // size: size,
      // leading: leading,
      // color: color,
      // weight: weight,
      // Styletron-only conditional props...
      // ...(textAlign && { textAlign: textAlign }),
      // ...(allCaps && { allCaps: allCaps }),
      // ...(lineClamp && { lineClamp: lineClamp }),
      // ...(lineClamp &&
      //   noLineClampForPrint && { $noLineClampForPrint: noLineClampForPrint }),
    },
    children,
  )
}

Text.propTypes = propTypes
Text.defaultProps = defaultProps

export default styled(Text)

// export default styled(
//   Text,
//   ({
//     $theme,
//     size,
//     weight,
//     leading,
//     color,
//     textAlign,
//     allCaps,
//     lineClamp,
//     $noLineClampForPrint,
//   }) => {
//     console.log(
//       weight,
//       size,
//       (weight && size && styles.weight[weight][size]: {}),
//     )
//     // console.log(size, weight, color, allCaps)
//     return {
//       ...(allCaps && {
//         textTransform: 'uppercase',
//       }),
//       ...(lineClamp && {
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//       }),
//       ...($noLineClampForPrint && {
//         display: 'block',
//         maxHeight: 'none',
//         overflow: 'visible',
//         WebkitLineClamp: 'none',
//         whiteSpace: 'normal',
//       }),
//       ...(size && { fontSize: `${styles.size[size]}px` }),
//       ...(weight && size && { fontWeight: styles.weight[weight][size] }),
//     }
//   },
// )
