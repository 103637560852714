import { styled } from 'styletron-react';

export const OffCanvasBackdrop = styled('div', ({ $show }) => ({
  zIndex: 10000,
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'opacity .35s',
  opacity: $show ? 1 : 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
}));

const OffCanvasRoot = styled('div', ({ $open, $width, $theme }) => ({
  ...$theme.typography.base,
  zIndex: 10001,
  position: 'fixed',
  top: '0',
  left: '100%',
  width: `${$width}px`,
  maxWidth: '75%',
  height: '100%',
  backgroundColor: $theme.palette.paper, // 'hsla(0,0%,100%,.97)',
  transition: 'transform .35s cubic-bezier(.215,.61,.355,1)',
  transform: `translateX(${$open ? '-100%' : '0%'})`,
  // ...($open ? { transform: 'translateX(-100%)' } : {}),
  paddingTop: `${$theme.spacing(5)}px`,
  overflowY: 'auto',
}));

export default OffCanvasRoot;
