import { styled } from 'styletron-react';

// Theme Styles
const themeStyles = ({ $color, disabled, $theme }) => {
  let color = $color === 'default' ? 'gray200' : $color;
  if (disabled) color = 'gray50';

  const styles = $theme.styler({ color, swap: false, outlined: true });

  return {
    ...styles,
    borderColor: styles.backgroundColor,
  };
};

const BaseInput = styled('input', ({ $theme, ...otherProps }) => {
  const styles = themeStyles({ $theme, ...otherProps });

  return otherProps.as !== 'radio'
    ? {
        position: 'relative',
        appearance: 'none',
        display: 'flex',
        width: '16px',
        height: '16px',
        backgroundColor: $theme.palette.common.white,
        border: `1px solid ${styles.borderColor}`,
        borderRadius: '2px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxSizing: 'border-box',
        padding: '0',
        ':focus': { outline: 'none' },
        ':before': {
          position: 'absolute',
          top: '-1px',
          color: styles.color,
          display: 'none',
          verticalAlign: 'middle',
          lineHeight: 1,
          fontWeight: 400,
          fontStyle: 'normal',
          fontFamily: 'parkholidays !important',
          content: '"\\F00C"',
          speak: 'none',
          textDecoration: 'inherit',
          textTransform: 'none',
          textRendering: 'auto',
          fontSize: '15px',
        },
        ':checked': {
          backgroundColor: styles.backgroundColor,
          // border: 'none',
          ':before': {
            display: 'inline-block',
          },
        },
      }
    : {};
});

export default BaseInput;
