const createMixins = (breakpoints, spacing) => {
  // mixins - 3rd paramater

  // const minSpacing = 1;
  // const maxSpacing = 2;
  const { keys } = breakpoints;
  // const keys = breakpoints.keys.filter(key => (
  //   breakpoints.values[breakpoints.keys.indexOf(key)] > 0
  // ));

  // Inner Methods
  const breakpointStyles = (property, prefix = '') =>
    keys.reduce((prev, current) => {
      // const index = breakpoints.keys.indexOf(current);
      const currentSpacing = 2;
        // ((maxSpacing - minSpacing) / keys.length) * (index + 1) + minSpacing;

      return {
        ...prev,
        [breakpoints.up(current)]: {
          [`${property}Left`]: `${prefix}${spacing(currentSpacing)}px`,
          [`${property}Right`]: `${prefix}${spacing(currentSpacing)}px`,
        },
      };
    }, {});

  const gutters = (...args) => {
    const styles = args.length > 0 && args[0] !== undefined ? args[0] : {};

    return {
      ...styles,
      ...breakpointStyles('padding'),
    };
  };

  return {
    gutters,
    grid: { ...breakpointStyles('margin', '-') },
    toolbar: {
      minHeight: '44px',
      [breakpoints.up('sm')]: { minHeight: '44px' },
    },
  };
};

export default createMixins;
