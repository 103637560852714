import React from 'react'
import { useStyletron } from 'styletron-react'

import Icon from '../icon'
import { IconButton } from '../button'
import FormField from './FormField'

const PasswordField = ({ ...otherProps }) => {
  const [show, setShow] = React.useState(false)
  const [css] = useStyletron()
  const type = show ? 'text' : 'password'

  return (
    <FormField
      {...otherProps}
      type={type}
      afterEnhancer={
        <IconButton
          type="button"
          color="white"
          tabIndex="-1"
          onClick={() => setShow(!show)}
          className={css({ borderColor: 'rgb(217, 217, 217)', borderLeft: 0 })}
        >
          <Icon name={show ? 'eye-slash' : 'eye'} />
        </IconButton>
      }
    />
  )
}

export default PasswordField
