/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Theme from '../../../context/theme';
import FormGroupRoot from './style';

const FormGroup = ({ children, ...otherProps }) => {
  const { theme } = React.useContext(Theme);

  return (
    <FormGroupRoot $theme={theme} {...otherProps}>
      {children}
    </FormGroupRoot>
  );
};

/** Component Property Types */
// eslint-disable-next-line react/no-typos
FormGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FormGroup;
