/** @flow */
import React from 'react';
import PropTypes from 'prop-types';

/** Components */
import Theme from '../../../context/theme';
import NavbarRoot from './style';

/**
 * Navigation Bar
 *
 * @param {*} props
 * @returns
 */
const Navbar = ({ children, ...otherProps }) => {
  const { theme } = React.useContext(Theme);

  return (
    <NavbarRoot
      role="navigation"
      $theme={theme}
      {...otherProps}
      aria-label={otherProps['aria-label'] || 'navigation'}
    >
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          style: {
            ...(child.props.style || {}),
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          },
        }),
      )}
    </NavbarRoot>
  );
};

/** Component Property Types */
Navbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Navbar;
