// @flow
import React from 'react';
import { DefaultTheme } from './index.js';

export const ThemeContext = React.createContext(DefaultTheme);

const ThemeProvider = props => {
  const { theme, children } = props;
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
