import { styled } from 'styletron-react';

const borderStyles = ({ $color, $size, $borderStyle, $theme }) => {
  const colors = { ...$theme.palette, ...$theme.palette.common };
  const defaultColor = $color === 'default' ? colors.divider : null;
  const borderColor = defaultColor || colors[$color];

  return {
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    borderWidth: `${$size}px`,
    borderStyle: $borderStyle,
    borderColor,
  };
};

const BaseDivider = styled('hr', ({ $color, $opacity, $size, $borderStyle, $theme }) => ({
  width: '100%',
  marginTop: `${$theme.spacing(2)}px`,
  marginBottom: `${$theme.spacing(2)}px`,
  ...borderStyles({ $color, $size, $borderStyle, $theme }),
  opacity: $opacity,
}));

export default BaseDivider;
