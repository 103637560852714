import { styled } from 'styletron-react';

const BaseLabel = styled(
  'label',
  ({ $fullWidth, $labelPosition, $srOnly, $theme, ...otherProps }) => {
    const directions = {
      top: 'column-reverse',
      left: 'row-reverse',
      bottom: 'column',
      right: 'row',
    };

    return {
      ...$theme.typography.base,
      display: 'inline-flex',
      flexDirection: directions[$labelPosition],
      alignItems: 'center',
      cursor: otherProps.control ? 'pointer' : 'initial',
      ...($fullWidth ? { display: 'block', width: '100%' } : {}),
      ...($srOnly
        ? {
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0px',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0,0,0,0)',
            border: '0',
          }
        : {}),
    };
  },
);

export default BaseLabel;
