import { styled } from 'styletron-react';

// Theme Styles
const themeStyles = ({ $color, swap, $theme }) => {
  if (['currentColor', 'inherit'].includes($color)) return { color: $color };
  const color = $color === 'default' ? 'gray100' : $color;
  const styles = $theme.styler({ color, swap, outlined: false });
  return styles;
};

/** Styled Icon Component */
export const BurgerMenuLine = styled(
  'div',
  ({ $bar, $color, $active, $theme }) => {
    const { color } = themeStyles({ $color, swap: false, $theme });

    const activeStyles = {
      bar1: { transform: 'rotate(-45deg) translate(-5px, 5px)' }, // -9, -6px
      bar2: { opacity: 0 },
      bar3: { transform: 'rotate(45deg) translate(-6px, -7px)' }, // -8px, -8px
    };

    return {
      display: 'block',
      width: '24px',
      height: '2px',
      margin: '3px auto',
      backgroundColor: color,
      transition: '0.4s',
      ...($active ? activeStyles[`bar${$bar}`] : {}),
    };
  },
);

/** Styled Icon Component */
const IconSvgRoot = styled('svg', ({ $color, $size, $theme }) => {
  const { color } = themeStyles({ $color, swap: true, $theme });
  return {
    display: 'inline-block',
    width: `${$size}px`,
    height: `${$size}px`,
    strokeWidth: '0px',
    stroke: color,
    fill: color,
  };
});

export default IconSvgRoot;
