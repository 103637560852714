/** @flow */
import React from 'react'
import PropTypes from 'prop-types'

/** Components */
import themeBreakpoints from '../../../context/theme/functions/breakpoints'

const visibleOnPropTypes = {
  breakpoints: PropTypes.arrayOf(PropTypes.string),
  hiddenOn: PropTypes.bool,
}

/**
 * Visible On
 * Responsive component for adding and removing components from the dom
 * based on screen size.
 *
 * @param {*} { component, breakpoints, hiddenOn, ...otherProps }
 * @returns
 */
const VisibleOn = ({ component, breakpoints, hiddenOn, ...otherProps }) => {
  const [isVisible, setIsVisible] = React.useState(hiddenOn)

  const updateVisibility = () => {
    const { values, keys } = themeBreakpoints()
    const breakpointValue = [...values]
      .reverse()
      .find(v => v <= window.innerWidth)
    const index = values.indexOf(breakpointValue)
    const breakpointKey = keys[index]

    setIsVisible(hiddenOn)

    breakpoints.forEach(bp => {
      if (bp === breakpointKey) setIsVisible(!hiddenOn)
    })
  }

  // USE EFFECT
  React.useEffect(() => {
    updateVisibility()
    window.addEventListener('resize', updateVisibility)
    return () => {
      window.removeEventListener('resize', updateVisibility)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isVisible ? React.cloneElement(component, { ...otherProps }) : null
}

/**
 * Hidden On
 * Alias component for VisibleOn, reverses the way it works
 *
 * @param {*} { ...otherProps }
 */
export const HiddenOn = ({ ...otherProps }) => (
  <VisibleOn hiddenOn {...otherProps} />
)

/** Component Property Types */
VisibleOn.propTypes = visibleOnPropTypes
VisibleOn.defaultProps = {
  breakpoints: themeBreakpoints.keys,
  hiddenOn: false,
}

export default VisibleOn
