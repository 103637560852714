import colors from './common';

export default {
  // ROSE
  rose: colors.magenta400,
  rose50: colors.magenta50,
  rose100: colors.magenta100,
  rose200: colors.magenta200,
  rose300: colors.magenta300,
  rose400: colors.magenta400,
  rose500: colors.magenta500,
  rose600: colors.magenta600,
  rose700: colors.magenta700,
  rose800: colors.magenta800,
  rose900: colors.magenta900,
  // SUN
  sun: colors.orange400,
  sun50: colors.orange50,
  sun100: colors.orange100,
  sun200: colors.orange200,
  sun300: colors.orange300,
  sun400: colors.orange400,
  sun500: colors.orange500,
  sun600: colors.orange600,
  sun700: colors.orange700,
  sun800: colors.orange800,
  sun900: colors.orange900,
  // SALE
  sale: colors.brown400,
  sale50: colors.brown50,
  sale100: colors.brown100,
  sale200: colors.brown200,
  sale300: colors.brown300,
  sale400: colors.brown400,
  sale500: colors.brown500,
  sale600: colors.brown600,
  sale700: colors.brown700,
  sale800: colors.brown800,
  sale900: colors.brown900,
};
