import colors from './common';

export default {
  // ERROR
  error: colors.red400,
  error50: colors.red50,
  error100: colors.red100,
  error200: colors.red200,
  error300: colors.red300,
  error400: colors.red400,
  error500: colors.red500,
  error600: colors.red600,
  error700: colors.red700,
  error800: colors.red800,
  error900: colors.red900,
  // BACKGROUND
  background: '#F3F6F9',
  paper: '#F3F6F9',
  // TEXT
  text: '#666666',
  textSecondary: '#999999',
  textDisabled: 'rgba(0, 0, 0, 0.38)',
  textHint: 'rgba(0, 0, 0, 0.38)',
  // DIVIDER
  divider: 'rgba(0, 0, 0, 0.12)',
};
