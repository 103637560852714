/*
  DO NOT MODIFY THIS FILE
*/
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { styled } from '../styles'

export const KIND = {
  button: 'button',
  submit: 'submit',
}

const propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  id: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.keys(KIND)),
  href: PropTypes.string,
  openInNewWindow: PropTypes.bool,
  role: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  ariaLabel: PropTypes.string,
  ariaSelected: PropTypes.bool,
  ariaCurrent: PropTypes.oneOf([
    'page',
    'step',
    'location',
    'date',
    'time',
    'true',
  ]),
  ariaDescribedBy: PropTypes.string,
  ariaDisabled: PropTypes.bool,
  ariaHasPopup: PropTypes.bool,
  ariaExpanded: PropTypes.bool,
  ariaControls: PropTypes.string,
  ariaPressed: PropTypes.bool,
  removeOutlineOnPress: PropTypes.bool,
  itemProp: PropTypes.string,
  loading: PropTypes.bool,
  buttonAnchorRef: PropTypes.func,
}

const defaultProps = {
  className: null,
  style: null,
  id: undefined,
  children: null,
  type: KIND.button,
  href: null,
  openInNewWindow: false,
  role: null,
  disabled: null,
  onClick: null,
  onFocus: null,
  onBlur: null,
  onMouseDown: null,
  onMouseUp: null,
  onMouseEnter: null,
  onMouseLeave: null,
  onDragStart: null,
  onDragEnd: null,
  ariaLabel: undefined,
  ariaSelected: undefined,
  ariaCurrent: null,
  ariaDescribedBy: undefined,
  ariaDisabled: false,
  ariaHasPopup: false,
  ariaExpanded: undefined,
  ariaControls: undefined,
  ariaPressed: undefined,
  removeOutlineOnPress: false,
  itemProp: undefined,
  loading: false,
  buttonAnchorRef: null,
}

const BaseButton = ({
  className,
  style,
  id,
  children,
  type,
  href,
  openInNewWindow,
  role,
  disabled,
  onClick,
  onFocus,
  onBlur,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  onDragStart,
  onDragEnd,
  ariaLabel,
  ariaSelected,
  ariaCurrent,
  ariaDescribedBy,
  ariaDisabled,
  ariaHasPopup,
  ariaExpanded,
  ariaControls,
  ariaPressed,
  removeOutlineOnPress,
  itemProp,
  loading,
  buttonAnchorRef,
}) => {
  // Handle onMouseUp when needing to remove outline
  const onMouseUpFunc =
    onMouseUp || removeOutlineOnPress
      ? function(e) {
          removeOutlineOnPress && e.currentTarget.blur()
          onMouseUp && onMouseUp(e)
        }
      : undefined
  // Properties that are shared across
  //  - button
  //  - Link (react-router-dom)
  //  - a
  const sharedProps = {
    className: className ? className : undefined,
    style: style,
    id: id ? id : undefined,
    role: role ? role : undefined,
    onClick: onClick ? onClick : undefined,
    onFocus: onFocus ? onFocus : undefined,
    onBlur: onBlur ? onBlur : undefined,
    onMouseDown: onMouseDown ? onMouseDown : undefined,
    onMouseUp: onMouseUpFunc,
    onMouseEnter: onMouseEnter ? onMouseEnter : undefined,
    onMouseLeave: onMouseLeave ? onMouseLeave : undefined,
    onDragStart: onDragStart ? onDragStart : undefined,
    onDragEnd: onDragEnd ? onDragEnd : undefined,
    'aria-label': ariaLabel ? ariaLabel : undefined,
    'aria-selected': ariaSelected ? ariaSelected : undefined,
    'aria-current': ariaCurrent ? ariaCurrenth : undefined,
    'aria-describedby': ariaDescribedBy ? ariaDescribedBy : undefined,
    'aria-disabled': !!ariaDisabled || undefined,
    'aria-haspopup': !!ariaHasPopup || undefined,
    'aria-expanded': ariaExpanded ? ariaExpanded : undefined,
    'aria-controls': ariaControls ? ariaControls : undefined,
    'aria-busy': loading ? loading : undefined,
    itemProp: itemProp ? itemProp : undefined,
  }

  const isButton = !href || disabled || loading

  if (isButton) {
    return React.createElement(
      'button',
      {
        type: loading ? KIND.button : type,
        disabled: (disabled && !loading) || undefined,
        ref: buttonAnchorRef ? buttonAnchorRef : undefined,
        ...sharedProps,
      },
      children,
    )
  }

  const isExternalLink =
    href && (href.startsWith('//') || !href.startsWith('/'))

  return React.createElement(
    isExternalLink ? 'a' : Link,
    {
      ...(isExternalLink ? { href } : { to: href }),
      target: openInNewWindow ? '_blank' : null,
      rel: openInNewWindow && isExternalLink ? 'noopener noreferrer' : null,
      ref: buttonAnchorRef ? buttonAnchorRef : undefined,
      ...sharedProps,
    },
    children,
  )
}

BaseButton.propTypes = propTypes
BaseButton.defaultProps = defaultProps

export default styled(BaseButton, ({ $theme }) => ({
  display: 'inline-block',
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  paddingTop: '14px',
  paddingRight: '16px',
  paddingBottom: '14px',
  paddingLeft: '16px',
  position: 'relative',
  fontFamily: '"Source Sans Pro", sans-serif',
  fontSize: '16px',
  textAlign: 'center',
  textDecoration: 'none',
  borderLeftWidth: 0,
  borderTopWidth: 0,
  borderRightWidth: 0,
  borderBottomWidth: 0,
  borderLeftStyle: 'none',
  borderTopStyle: 'none',
  borderRightStyle: 'none',
  borderBottomStyle: 'none',
  WebkitAppearance: 'none',
  transitionProperty: 'background',
  transitionDuration: $theme.animation.timing100,
  transitionTimingFunction: $theme.animation.easeOutCurve,
  cursor: 'pointer',
  ':disabled': {
    cursor: 'not-allowed',
    backgroundColor: $theme.colors.buttonDisabledFill,
    color: $theme.colors.buttonDisabledText,
  },
}))
