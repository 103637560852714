import colors from './common'

export default {
  // ERROR
  error: colors.red400,
  error50: colors.red50,
  error100: colors.red100,
  error200: colors.red200,
  error300: colors.red300,
  error400: colors.red400,
  error500: colors.red500,
  error600: colors.red600,
  error700: colors.red700,
  error800: colors.red800,
  error900: colors.red900,
  // SUCCESS
  success: colors.green400,
  success50: colors.green50,
  success100: colors.green100,
  success200: colors.green200,
  success300: colors.green300,
  success400: colors.green400,
  success500: colors.green500,
  success600: colors.green600,
  success700: colors.green700,
  success800: colors.green800,
  success900: colors.green900,
  // INFO
  info: colors.cyan400,
  info50: colors.cyan50,
  info100: colors.cyan100,
  info200: colors.cyan200,
  info300: colors.cyan300,
  info400: colors.cyan400,
  info500: colors.cyan500,
  info600: colors.cyan600,
  info700: colors.cyan700,
  info800: colors.cyan800,
  info900: colors.cyan900,
  // WARNING
  warning: colors.orange400,
  warning50: colors.orange50,
  warning100: colors.orange100,
  warning200: colors.orange200,
  warning300: colors.orange300,
  warning400: colors.orange400,
  warning500: colors.orange500,
  warning600: colors.orange600,
  warning700: colors.orange700,
  warning800: colors.orange800,
  warning900: colors.orange900,
  // BACKGROUND
  background: '#F3F6F9',
  paper: '#F3F6F9',
  // TEXT
  text: '#666666',
  textSecondary: '#999999',
  textDisabled: 'rgba(0, 0, 0, 0.38)',
  textHint: 'rgba(0, 0, 0, 0.38)',
  // DIVIDER
  divider: 'rgba(0, 0, 0, 0.12)',
}
