import { styled } from 'styletron-react';

/** Toolbar Item Container */
const NavbarRoot = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
});

export default NavbarRoot;
