const createBreakpoints = () => {
  const keys = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
  const values = [0, 576, 768, 992, 1280, 1440]; // [0, 600, 960, 1280, 1920];
  const unit = 'px';
  const step = 5;

  // Methods
  const up = key => {
    const index = keys.indexOf(key);
    const value = typeof values[index] === 'number' ? values[index] : index;
    return `@media (min-width: ${value}${unit})`;
  };

  const down = key => {
    const endIndex = keys.indexOf(key) + 1;
    const upperbound = values[endIndex];

    if (endIndex === keys.length) return up('xs'); // xl down applies to all sizes

    const value =
      typeof upperbound === 'number' && endIndex > 0 ? upperbound : key;
    return `@media (max-width: ${value - step / 100}${unit})`;
  };

  const between = (start, end) => {
    const endIndex = keys.indexOf(end) + 1;
    if (endIndex === keys.length) return up(start);

    return `@media (min-width: ${
      values[start]
    }${unit}) and (max-width: ${values[endIndex] - step / 100}${unit})`;
  };

  const only = key => between(key, key);
  const width = key => values[keys.indexOf(key)];

  return {
    keys,
    values,
    up,
    down,
    between,
    only,
    width,
  };
};

export default createBreakpoints;
