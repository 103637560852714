const createSpacing = (...args) => {
  const spacingInput = args.length > 0 && args[0] !== undefined ? args[0] : 8;

  // Inner Methods
  const transform =
    typeof spacingInput === 'function'
      ? spacingInput
      : factor => spacingInput * factor;

  const spacing = (...spacingArgs) => {
    if (spacingArgs.length === 0) return transform(1);
    if (spacingArgs.length === 1) return transform(spacingArgs[0]);

    return spacingArgs
      .map(factor => {
        const output = transform(factor);
        return typeof output === 'number' ? `${output}px` : output;
      })
      .join(' ');
  };

  return spacing;
};

export default createSpacing;
