import { styled } from 'styletron-react';

// Text Color
const colorVariant = ({ $color, $theme }) => {
  const colors = { ...$theme.palette, ...$theme.palette.common };
  const defaultColor = $color === 'default' ? 'corporate' : null;

  return {
    color: $color === 'inherit' ? 'inherit' : colors[defaultColor || $color],
  };
};

/** Base Anchor Component */
const AnchorRoot = styled('a', ({ $color, $theme }) => ({
  ...$theme.typography.base,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'inherit',
  // textDecoration: 'none',
  ...colorVariant({ $color, $theme }),
}));

export default AnchorRoot;
