import colors from './common';

export default {
  // CORPORATE
  corporatePale: colors.blue50,
  corporateLight: colors.blue200,
  corporate: colors.blue400,
  corporateDark: colors.blue600,
  //
  corporate40: colors.white,
  corporate50: colors.blue50,
  corporate100: colors.blue100,
  corporate200: colors.blue200,
  corporate300: colors.blue300,
  corporate400: colors.blue400,
  corporate500: colors.blue500,
  corporate600: colors.blue600,
  corporate700: colors.blue700,
  corporate800: colors.blue800,
  corporate900: colors.blue900,
  // HOLIDAYS
  holidaysPale: colors.cyan50,
  holidaysLight: colors.cyan200,
  holidays: colors.cyan400,
  holidaysDark: colors.cyan600,
  //
  holidays50: colors.cyan50,
  holidays100: colors.cyan100,
  holidays200: colors.cyan200,
  holidays300: colors.cyan300,
  holidays400: colors.cyan400,
  holidays500: colors.cyan500,
  holidays600: colors.cyan600,
  holidays700: colors.cyan700,
  holidays800: colors.cyan800,
  holidays900: colors.cyan900,
  // TOURING
  touringPale: colors.green50,
  touringLight: colors.green200,
  touring: colors.green400,
  touringDark: colors.green600,
  //
  touring50: colors.green50,
  touring100: colors.green100,
  touring200: colors.green200,
  touring300: colors.green300,
  touring400: colors.green400,
  touring500: colors.green500,
  touring600: colors.green600,
  touring700: colors.green700,
  touring800: colors.green800,
  touring900: colors.green900,
  // OWNERSHIP
  ownershipPale: colors.purple50,
  ownershipLight: colors.purple200,
  ownership: colors.purple400,
  ownershipDark: colors.purple600,
  // 
  ownership50: colors.purple50,
  ownership100: colors.purple100,
  ownership200: colors.purple200,
  ownership300: colors.purple300,
  ownership400: colors.purple400,
  ownership500: colors.purple500,
  ownership600: colors.purple600,
  ownership700: colors.purple700,
  ownership800: colors.purple800,
  ownership900: colors.purple900,
};
