/* eslint-disable object-curly-newline */
import { styled } from 'styletron-react';

// Text Color
const colorVariant = ({ $color, $theme }) => {
  if ($color === 'inherit') return {};

  const colors = { ...$theme.palette, ...$theme.palette.common };
  const defaultColor = $color === 'default' ? 'text' : null;

  return { color: colors[defaultColor || $color] };
};

// Text Weight
const textWeight = ({ $weight, $theme }) => {
  if ($weight === 'initial') return {};
  const variant = $weight
    .split('-')
    .map(v => `${v[0].toUpperCase()}${v.substring(1)}`)
    .join('');

  return { fontWeight: $theme.typography[`fontWeight${variant}`] };
};

// Base Style
const baseStyle = ({ $as, $variant, $theme }) => {
  const exists = $theme.typography[$as] !== undefined;
  const newVariant =
    $variant !== 'default' ? $variant || (exists ? $as : 'base') : 'base';

  return {
    ...$theme.typography[newVariant],
  };
};

/** Base Component */
const BaseTypography = styled(
  'span',
  ({ $hasIcon, $align, $transform, $noMargins, $theme, ...otherProps }) => ({
    ...($hasIcon ? { display: 'flex', alignItems: 'center' } : {}),
    // Typography variation style
    ...baseStyle({ $theme, ...otherProps }),
    // Optional Styles
    ...colorVariant({ $theme, ...otherProps }),
    ...textWeight({ $theme, ...otherProps }),
    ...(otherProps.$as === 'strong'
      ? { fontWeight: $theme.typography.fontWeightBold }
      : {}),
    ...(otherProps.$as === 'em' ? { fontStyle: 'italic' } : {}),
    ...($transform ? { textTransform: $transform } : {}),
    ...($align ? { textAlign: $align } : {}),
    marginTop: 0,
    ...($noMargins ? { marginLeft: 0, marginRight: 0, marginBottom: 0 } : {}),
  }),
);

export default BaseTypography;
