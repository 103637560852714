import React from 'react'
import PropTypes from 'prop-types'

import CardRoot, {
  CardHeaderRoot,
  CardActionsRoot,
  CardMediaRoot,
  CardContentRoot,
} from './styled'

import Theme from '../../../context/theme'

const cardPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  elevation: PropTypes.number,
  outlined: PropTypes.bool,
  rounded: PropTypes.bool,
}

const Card = ({
  children,
  color,
  elevation,
  outlined,
  rounded,
  staticContext,
  ...otherProps
}) => {
  const { theme } = React.useContext(Theme)

  /** ADDED FOLLOWING TO RESOLVE STATICCONTEXT BEING PASSED TO DOM ERROR */
  // eslint-disable-next-line no-param-reassign
  if (staticContext) otherProps.staticContext = staticContext
  /** END */

  return (
    <CardRoot
      $color={color}
      $elevation={elevation}
      $outlined={outlined}
      $rounded={rounded}
      $theme={theme}
      {...otherProps}
    >
      {children}
    </CardRoot>
  )
}

const cardActionsPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export const CardActions = ({ children, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  return (
    <CardActionsRoot $theme={theme} {...otherProps}>
      {children}
    </CardActionsRoot>
  )
}

export const CardMedia = ({ image, ...otherProps }) => {
  return <CardMediaRoot $image={image} {...otherProps} />
}

const cardHeaderPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  compact: PropTypes.bool,
}

export const CardHeader = ({ children, compact, color, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  return (
    <CardHeaderRoot
      $compact={compact}
      $color={color}
      $theme={theme}
      {...otherProps}
    >
      {children}
    </CardHeaderRoot>
  )
}

const cardContentPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export const CardContent = ({ children, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  return (
    <CardContentRoot $theme={theme} {...otherProps}>
      {children}
    </CardContentRoot>
  )
}

/** Component Property Types */
// eslint-disable-next-line react/no-typos
Card.propTypes = cardPropTypes
Card.defaultProps = {
  color: 'default',
  elevation: 0,
  outlined: false,
  rounded: true,
}

CardHeader.propTypes = cardHeaderPropTypes
CardHeader.defaultProps = {
  color: 'inherit',
  compact: false,
}

CardActions.propTypes = cardActionsPropTypes
CardContent.propTypes = cardContentPropTypes
CardContent.defaultProps = {
  children: undefined,
}

export default Card
