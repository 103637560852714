import React from 'react';
import PropTypes from 'prop-types';

import Theme from '../../../context/theme';
import { CloseButton } from '../button';
import OffCanvasRoot, { OffCanvasBackdrop } from './style';

import './offcanvas.css';

/**
 * OffCanvas Component
 *
 * @param {*} { children, width, open, onClose, ...otherProps }
 * @returns
 */
const OffCanvas = ({
  children,
  width,
  open,
  onClose,
  // eslint-disable-next-line react/prop-types
  staticContext,
  ...otherProps
}) => {
  const { theme } = React.useContext(Theme);

  /** ADDED FOLLOWING TO RESOLVE STATICCONTEXT BEING PASSED TO DOM ERROR */
  // eslint-disable-next-line no-param-reassign
  if (staticContext) otherProps.staticContext = staticContext;
  /** END */

  React.useEffect(() => {
    const element = document.getElementsByTagName('html')[0];
    if (open) element.classList.add('offcanvas-open');
    else element.classList.remove('offcanvas-open');
  }, [open]);

  return (
    <>
      <OffCanvasRoot $open={open} $width={width} $theme={theme} {...otherProps}>
        <CloseButton
          rounded={false}
          onClick={onClose}
          style={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
          }}
        />
        {children}
      </OffCanvasRoot>
      {open ? <OffCanvasBackdrop $show={open} onClick={onClose} /> : null}
    </>
  );
};

export default OffCanvas;

/** Component Property Types */
OffCanvas.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  width: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

OffCanvas.defaultProps = {
  width: 320,
  open: false,
  onClose: undefined,
};
