import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';
import { LoadingWrapper, LoadingSpinner, LoadingMessage } from './style';
import Theme from '../../../context/theme';

const Loading = ({ text, color, ...otherProps }) => {
  const { theme } = React.useContext(Theme);
  return (
    <LoadingWrapper {...otherProps} $color={color} $theme={theme}>
      <LoadingSpinner $theme={theme}>
        <Icon
          name="spinner"
          size={38}
          color={color === 'default' ? 'text' : color}
        />
      </LoadingSpinner>
      <LoadingMessage $color={color} $theme={theme}>
        {text}
      </LoadingMessage>
    </LoadingWrapper>
  );
};

Loading.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

Loading.defaultProps = {
  text: 'Loading',
  color: 'default',
};

export default Loading;
