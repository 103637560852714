import { ThemeContext } from './theme-provider.js'

export {
  createThemedStyled,
  createThemedWithStyle,
  createThemedUseStyletron,
  styled,
  withStyle,
  useStyletron,
} from './styled.js'
export { default as ThemeProvider } from './theme-provider.js'
export const ThemeConsumer = ThemeContext.Consumer
export { ThemeContext }
