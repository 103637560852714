import React from 'react'
import PropTypes from 'prop-types'

/** THEME COLORS */
import systemColors from './colors/system'
import brandColors from './colors/brand'
import accentColors from './colors/accent'
import commonColors from './colors/common'
/** THEME METHODS */
import createBreakpoints from './functions/breakpoints'
import createMixins from './functions/mixins'
import createSpacing from './functions/spacing'
import styler from './functions/styler'
/** MISC */
import shadows from './shadows'
import typography from './typography'
import shape from './shape'

// New....
import colors from './colors'
import animation from './animation'
import lighting from './lighting'

// PL2K
export const pl2kBrandColors = {
  corporate: commonColors.blue350,
  corporate50: commonColors.blue360,
  corporateDark: commonColors.blue360,
  corporate40: commonColors.gold100,
  pl2kBlack: commonColors.blue1000,
  ownershipDark: commonColors.gray25,
  ownership: commonColors.gold100,
}

const DefaultTheme = {
  // @deprecating the below palette
  palette: {
    common: { ...commonColors },
    ...brandColors,
    ...accentColors,
    ...systemColors,
  },
  shape,
  shadows,
  typography,
  zIndex: {
    appbar: 1100,
    sideMenu: 1200,
    modal: 1300,
  },
  // GENERATED STYLES
  breakpoints: createBreakpoints(),
  mixins: createMixins(createBreakpoints(), createSpacing()),
  spacing: createSpacing(),
  styler,
  // new stuff for @parkholidays/aphrodite
  colors,
  animation,
  lighting,
}

const mergedPalette = {
  ...DefaultTheme.palette,
  ...pl2kBrandColors,
}

const Pl2kTheme = {
  ...DefaultTheme,
  palette: mergedPalette,

}

export { DefaultTheme, Pl2kTheme }
